import i18next from "i18next";
import IActivityAction from "interfaces/IActivityAction";
import IActivityActionFeedbackDetails from "interfaces/IActivityActionFeedbackDetails";
import IActivityActionsWrapper from "interfaces/IActivityActionsWrapper";
import IActivityFilter from "interfaces/IActivityFilter";
import ICustomerActivity from "interfaces/ICustomerActivity";
import IOperator from "interfaces/IOperator";
import { NotificationManager } from "react-notifications";
import ExceptionTypes from "services/exceptionTypes";
import { ActionTypes } from "store/actionTypes";

import LoaderTypes from "../../enums/loaderTypes";
import TranslationMapper from "../../i18n/mapper";
import CleaningManagementService from "../../services/cleaningManagementService";
import { actionCreator, AppAction } from "../appAction";
import { startLoading, unsetAsLoading } from "./loaderActions";

export const fetchedCustomerActivityAction = actionCreator<
  ActionTypes.FETCHED_CUSTOMERACTIVITYACTION,
  IActivityActionFeedbackDetails[]
>(ActionTypes.FETCHED_CUSTOMERACTIVITYACTION);
export const fetchedCustomerVenueOperators = actionCreator<ActionTypes.FETCHED_CUSTOMERVENUE_OPERATORS, IOperator[]>(
  ActionTypes.FETCHED_CUSTOMERVENUE_OPERATORS
);
export const fetchedCustomerActivities = actionCreator<ActionTypes.FETCHED_CUSTOMERACTIVITIES, IActivityActionsWrapper>(
  ActionTypes.FETCHED_CUSTOMERACTIVITIES
);
export const fetchedCustomerActivityTypes = actionCreator<
  ActionTypes.FETCHED_CUSTOMER_ACTIVITYTYPES,
  ICustomerActivity[]
>(ActionTypes.FETCHED_CUSTOMER_ACTIVITYTYPES);

export function getCustomerActivities(customerId: string, activityFilter: IActivityFilter): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getActivityPlanningLoader = LoaderTypes.ActivityActions;

    dispatch(startLoading(getActivityPlanningLoader));

    cleaningManagementService
      .getCustomerActivityActions(customerId, activityFilter)
      .then((response: IActivityActionsWrapper) => {
        dispatch(fetchedCustomerActivities(response));
        if (response.showMoreResultsMessage) {
          NotificationManager.info(i18next.t(TranslationMapper.activityactions.not_all_results_shown));
        }
      })
      .catch(() => {
        dispatch(
          fetchedCustomerActivities({ activityActionResponse: [], totalResultsCount: 0, showMoreResultsMessage: false })
        );
        NotificationManager.error(i18next.t(TranslationMapper.pages.notifications.errormessageid));
      })
      .finally(() => dispatch(unsetAsLoading(getActivityPlanningLoader)));
  };
}

export function getActivityFeedbackRequests(activityId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getActivityPlanningLoader = LoaderTypes.ActivityActions;

    dispatch(startLoading(getActivityPlanningLoader));

    cleaningManagementService
      .getActivityFeedbackRequests(activityId)
      .then((response: IActivityActionFeedbackDetails[]) => {
        dispatch(fetchedCustomerActivityAction(response));
      })
      .catch(() => {
        dispatch(
          fetchedCustomerActivities({ activityActionResponse: [], totalResultsCount: 0, showMoreResultsMessage: false })
        );
        NotificationManager.error(i18next.t(TranslationMapper.pages.notifications.errormessageid));
      })
      .finally(() => dispatch(unsetAsLoading(getActivityPlanningLoader)));
  };
}

export function getCustomerVenueOperators(customerVenueId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getActivityPlanningLoader = LoaderTypes.ActivityActions;

    dispatch(startLoading(getActivityPlanningLoader));

    cleaningManagementService
      .getCustomerVenueOperators(customerVenueId)
      .then((response: IOperator[]) => {
        dispatch(fetchedCustomerVenueOperators(response));
      })
      .catch(() => {
        dispatch(fetchedCustomerVenueOperators([]));
        NotificationManager.error(i18next.t(TranslationMapper.pages.notifications.errormessageid));
      })
      .finally(() => dispatch(unsetAsLoading(getActivityPlanningLoader)));
  };
}

export function getCustomerActivityTypes(customerId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getActivityloggingLoader = LoaderTypes.CustomerActivities;

    dispatch(startLoading(getActivityloggingLoader));

    cleaningManagementService
      .getCustomerActivityTypes(customerId)
      .then((customerActivities: ICustomerActivity[]) => {
        dispatch(fetchedCustomerActivityTypes(customerActivities));
      })
      .catch(() => {
        dispatch(fetchedCustomerActivityTypes([]));
        NotificationManager.error(i18next.t(TranslationMapper.pages.notifications.errormessageid));
      })
      .finally(() => dispatch(unsetAsLoading(getActivityloggingLoader)));
  };
}

export function updateCustomerPlannedActivityStatus(
  customerId: string,
  activityFilter: IActivityFilter,
  selectedActivityActions: IActivityAction[]
): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getActivityloggingLoader = LoaderTypes.CustomerActivities;

    dispatch(startLoading(getActivityloggingLoader));
    cleaningManagementService
      .updateCustomerActivityActions(customerId, selectedActivityActions)
      .then(() => {
        dispatch(getCustomerActivities(customerId, activityFilter));
        NotificationManager.success(i18next.t(TranslationMapper.pages.activityplanning.activitysuccessfullyfinished));
      })
      .catch((error: Error) => {
        if (error.message.includes(ExceptionTypes.MultipleCheckOutException.toString())) {
          NotificationManager.error(i18next.t(TranslationMapper.pages.activityplanning.multiplecheckouterror));
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.pages.activityplanning.updateerror));
        }
      })
      .finally(() => dispatch(unsetAsLoading(getActivityloggingLoader)));
  };
}

export function updateCustomerPlannedActivityOperator(
  customerId: string,
  activityFilter: IActivityFilter,
  selectedActivityActions: IActivityAction[]
): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getActivityloggingLoader = LoaderTypes.CustomerActivities;

    dispatch(startLoading(getActivityloggingLoader));
    cleaningManagementService
      .updateCustomerActivityActionsOperator(customerId, selectedActivityActions)
      .then(() => {
        dispatch(getCustomerActivities(customerId, activityFilter));
        NotificationManager.success(i18next.t(TranslationMapper.pages.activityplanning.activitysuccessfullyupdated));
      })
      .catch((error: Error) => {
        if (error.message.includes(ExceptionTypes.MultipleCheckOutException.toString())) {
          NotificationManager.error(i18next.t(TranslationMapper.pages.activityplanning.multiplecheckouterror));
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.pages.activityplanning.updateerror));
        }
      })
      .finally(() => dispatch(unsetAsLoading(getActivityloggingLoader)));
  };
}
