import IRequiredFloorNFCTags from "components/customerVenueWizard/interfaces/IRequiredFloorNFCTagObject";
import Source from "constants/sources";
import ObjectExistsStatus from "enums/objectExistsStatus";
import i18next from "i18next";
import ICustomer from "interfaces/ICustomer";
import ICustomerProspect from "interfaces/ICustomerProspect";
import CustomerProvider from "providers/customerProvider";
import { NotificationManager } from "react-notifications";
import ExceptionTypes from "services/exceptionTypes";

import { RoutingLinks } from "../../constants/routingLinks";
import LoaderTypes from "../../enums/loaderTypes";
import TranslationMapper from "../../i18n/mapper";
import ICustomerVenue from "../../interfaces/ICustomerVenue";
import CleaningManagementService from "../../services/cleaningManagementService";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";
import { fetchedCustomerActivities, fetchedCustomerActivityTypes, getCustomerActivityTypes } from "./activityActions";
import { getCustomerIncidentTypes } from "./incidentTypeActions";
import { setAsLoading, unsetAsLoading } from "./loaderActions";
import { fetchedCustomerLocationOverview, getCustomerLocationOverview, getCustomerLocations } from "./locationActions";
import { getOperatorsAsync } from "./operatorActions";

export const fetchedCustomers = actionCreator<ActionTypes.FETCHED_CUSTOMERS, ICustomer[]>(
  ActionTypes.FETCHED_CUSTOMERS
);
export const fetchedCustomerProspects = actionCreator<ActionTypes.FETCHED_CUSTOMER_PROSPECTS, ICustomerProspect[]>(
  ActionTypes.FETCHED_CUSTOMER_PROSPECTS
);

export const updateSelectedCustomer = actionCreator<ActionTypes.UPDATE_SELECTED_CUSTOMER, string>(
  ActionTypes.UPDATE_SELECTED_CUSTOMER
);
export const displayCustomerSelection = actionCreator<ActionTypes.SHOW_CUSTOMER_SELECTION, boolean>(
  ActionTypes.SHOW_CUSTOMER_SELECTION
);
export const updateHasLogbook = actionCreator<ActionTypes.UPDATE_HAS_LOGBOOK, boolean>(ActionTypes.UPDATE_HAS_LOGBOOK);
export const updateHasOperation = actionCreator<ActionTypes.UPDATE_HAS_OPERATION, boolean>(
  ActionTypes.UPDATE_HAS_OPERATION
);

export const fetchedRequiredLocationFloorNFCList = actionCreator<
  ActionTypes.FETCHED_REQUIRED_LOCATION_FLOOR_NFC_LIST,
  IRequiredFloorNFCTags | undefined
>(ActionTypes.FETCHED_REQUIRED_LOCATION_FLOOR_NFC_LIST);

export function getCustomers(): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.Customers));
    const cleaningManagementService = new CleaningManagementService();

    cleaningManagementService
      .getCustomerOverview()
      .then((customers) => {
        dispatch(fetchedCustomers(customers));
        dispatch(updateHasLogbook(customers.some((c) => c.hasLogbook)));
        dispatch(updateHasOperation(customers.some((c) => c.hasOperation)));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.Customers));
      });
  };
}

export function getCustomerProspects(source: string): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.CustomerProspects));
    const cleaningManagementService = new CleaningManagementService();

    cleaningManagementService.getCustomerProspects(source).then((customerProspects) => {
      dispatch(fetchedCustomerProspects(customerProspects));
      dispatch(unsetAsLoading(LoaderTypes.CustomerProspects));
    });
  };
}

export function deleteCustomer(customerId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const cleaningManagementService = new CleaningManagementService();

    cleaningManagementService
      .deleteCustomer(customerId)
      .then(() => {
        const customers = getState().customerState.customers.filter((c) => c.id !== customerId);
        dispatch(getCustomerProspects(Source.DigitalCleaningPlatform));
        dispatch(fetchedCustomers(customers));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.customeredit.error_deleting_customer));
      });
  };
}

export function upsertCustomer(customer: ICustomer): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(setAsLoading(LoaderTypes.Customers));

    cleaningManagementService
      .upsertCustomer(customer)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.customeredit.customer_updated_message));

        dispatch(getCustomers());

        window.location.href = RoutingLinks.customerManagement;
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.customeredit.error_upserting_customer));
        dispatch(unsetAsLoading(LoaderTypes.Customers));
      });
  };
}

export function upsertCustomerIfNotExists(
  customer: ICustomer,
  onCustomerExists: (status: ObjectExistsStatus) => void,
  customerUpserted: () => void
): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(setAsLoading(LoaderTypes.Locations));

    cleaningManagementService
      .checkCustomerExists(customer.name, customer.externalCustomerId)
      .then((status: ObjectExistsStatus) => {
        if (status === ObjectExistsStatus.IsActive || status === ObjectExistsStatus.IsDeleted) {
          onCustomerExists(status);
        } else {
          dispatch(upsertCustomer(customer));
          customerUpserted();
        }
      })
      .catch((error: Error) => {
        if (error.message.includes(ExceptionTypes.MultipleCustomersFoundException.toString())) {
          NotificationManager.error(i18next.t(TranslationMapper.pages.customeredit.multiplecustomersfounderror));
        } else {
          NotificationManager.error(i18next.t(TranslationMapper.pages.customeredit.errormessage));
        }
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.Locations));
      });
  };
}

export function updateActiveCustomer(activeCustomerId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    CustomerProvider.clear();
    dispatch(clearCustomerState());

    if (activeCustomerId !== "") {
      const activeCustomer = getState().customerState.customers.find((c) => c.id === activeCustomerId);
      if (activeCustomer) {
        CustomerProvider.saveActiveCustomer(activeCustomer);
      }

      dispatch(getCustomerActivityTypes(activeCustomerId));
      dispatch(getCustomerLocations(activeCustomerId));
      dispatch(getOperatorsAsync(activeCustomerId));
      dispatch(getCustomerIncidentTypes(activeCustomerId));
      dispatch(updateSelectedCustomer(activeCustomerId));

      // clear customer specific state
      dispatch(
        fetchedCustomerActivities({
          activityActionResponse: [],
          totalResultsCount: 0,
          showMoreResultsMessage: false,
        })
      );
    }
  };
}

export function getVenueExists(
  customerId: string,
  venue: ICustomerVenue,
  onVenueExists: (status: ObjectExistsStatus) => void,
  onVenueIsNew: () => void
): AppAction {
  return (): void => {
    const cleaningManagementService = new CleaningManagementService();

    cleaningManagementService
      .checkCustomerVenueExists(customerId, venue.externalVenueId)
      .then((status: ObjectExistsStatus) => {
        if (status === ObjectExistsStatus.IsActive || status === ObjectExistsStatus.IsDeleted) {
          onVenueExists(status);
        } else {
          onVenueIsNew();
        }
      });
  };
}

export function updateVenue(customerId: string, venue: ICustomerVenue, OnUpsertVenue?: () => void): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(setAsLoading(LoaderTypes.Locations));

    cleaningManagementService
      .upsertCustomerVenue(customerId, venue)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.savedchanges));
        dispatch(getCustomers());
        dispatch(getCustomerLocationOverview(customerId));
        if (OnUpsertVenue != null) {
          OnUpsertVenue();
        }
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.customeredit.errormessage_updatelocation));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.Locations));
      });
  };
}

export function deleteVenue(customerId: string, venueId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(setAsLoading(LoaderTypes.Locations));

    cleaningManagementService
      .deleteCustomerVenue(customerId, venueId)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.savedchanges));
        dispatch(getCustomers());
        dispatch(getCustomerLocationOverview(customerId));
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.pages.customeredit.errormessage_deletelocation));
      })
      .finally(() => {
        dispatch(unsetAsLoading(LoaderTypes.Locations));
      });
  };
}

export function showCustomerSelection(show: boolean): AppAction {
  return (dispatch): void => {
    dispatch(displayCustomerSelection(show));
  };
}

export function clearCustomerState(): AppAction {
  return (dispatch): void => {
    dispatch(
      fetchedCustomerActivities({
        activityActionResponse: [],
        totalResultsCount: 0,
        showMoreResultsMessage: false,
      })
    );
    dispatch(fetchedCustomerActivityTypes([]));
    dispatch(fetchedCustomerLocationOverview([]));
  };
}
