import ErrorTypes from "../../enums/errorTypes";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const setError = actionCreator<ActionTypes.SET_ERROR, ErrorTypes>(ActionTypes.SET_ERROR);
export const resetError = actionCreator<ActionTypes.RESET_ERROR, ErrorTypes>(ActionTypes.RESET_ERROR);

export function setAsError(error: ErrorTypes): AppAction {
  return (dispatch): void => {
    dispatch(setError(error));
  };
}

export function resetAsError(error: ErrorTypes): AppAction {
  return (dispatch): void => {
    dispatch(resetError(error));
  };
}
