import { IInputChangeEvent } from "components/interfaces/IInputChangeEvent";
import CheckBox from "components/material/checkbox/checkbox";
import IWizardStep from "components/wizard/interfaces/IWizardStep";
import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";

import ObjectExistsStatus from "../../enums/objectExistsStatus";
import CustomerVenueValidator from "./customerVenueValidator";
import ICustomerVenueDetailsStepProps from "./interfaces/ICustomerVenueDetailsStepProps";
import ICustomerVenueDetailsStepState from "./interfaces/ICustomerVenueDetailsStepState";
import { ICustomerVenueWizardStepsEnum } from "./interfaces/ICustomerVenueWizardStepsEnum";

class CustomerVenueDetailsStep extends Component<ICustomerVenueDetailsStepProps, ICustomerVenueDetailsStepState> {
  public constructor(props: ICustomerVenueDetailsStepProps) {
    super(props);

    const customerId = Guid.parse(this.props.customer.id).toString();

    const state = this.props.value ?? {
      customerId: customerId,
      name: "",
      externalVenueId: "",
      groupByFloors: false,
      reactivateBuilding: false,
      isFloorNFCRequired: false, // nfc check-in for floor is required
      isCheckInRequired: false, // nfc check-in for venue is required
    };

    this.state = state;
    this.onChange = this.onChange.bind(this);
    this.onChangeExternalVenueId = this.onChangeExternalVenueId.bind(this);
    this.handleGroupByFloorsChange = this.handleGroupByFloorsChange.bind(this);
    this.handleNfcRequiredForFloorsChange = this.handleNfcRequiredForFloorsChange.bind(this);
    this.handleReactivateBuildingChange = this.handleReactivateBuildingChange.bind(this);
    this.setScanningRequired = this.setScanningRequired.bind(this);
    this.propagateChange = this.propagateChange.bind(this);
  }

  public onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(current => ({ ...current, [name]: value }), this.propagateChange);
  }

  public onChangeExternalVenueId(event: React.ChangeEvent<HTMLInputElement>): void {
    this.onChange(event);

    this.setState({
      reactivateBuilding: false,
    });
  }

  private propagateChange(): void {
    const isValid = CustomerVenueValidator.areDetailsValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private get reactivateBuilding(): boolean {
    return this.state.reactivateBuilding || false;
  }

  private handleReactivateBuildingChange(event: IInputChangeEvent<boolean>): void {
    this.setState(
      {
        reactivateBuilding: event.target.value ?? false,
      },
      this.propagateChange
    );
  }

  private handleNfcRequiredForFloorsChange(event: IInputChangeEvent<boolean>): void {
    this.setState(
      {
        isFloorNFCRequired: event.target.value ?? false,
      },
      this.propagateChange
    );
  }

  private handleGroupByFloorsChange(event: IInputChangeEvent<boolean>): void {
    this.setState(
      {
        groupByFloors: event.target.value ?? false,
        isFloorNFCRequired: false,
      },
      this.propagateChange
    );
  }

  // venue scanning required
  private setScanningRequired(event: IInputChangeEvent<boolean>): void {
    this.setState(
      {
        isCheckInRequired: event.target.value ?? false,
      },
      this.propagateChange
    );
  }

  private get renderErrorMessage(): JSX.Element {
    if (this.props.customerVenueStatus === ObjectExistsStatus.IsNew) {
      return <></>;
    }

    return (
      <div className="customer-venue-details-modal-error-block">
        {this.props.customerVenueStatus === ObjectExistsStatus.IsDeleted && (
          <div>
            <small className="warning">
              {LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.activate_deleted_building)}
            </small>
            <div className="mt-4">
              <CheckBox
                name="group-floors-checkbox"
                description={LanguageProvider.t(TranslationMapper.pages.customeredit.reactivate_building)}
                onChange={this.handleReactivateBuildingChange}
                value={this.reactivateBuilding}
                id="group-reactivate-building-checkbox"
              />
            </div>
          </div>
        )}
        {this.props.customerVenueStatus === ObjectExistsStatus.IsActive && (
          <small className="warning">
            {LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.building_already_active)}
          </small>
        )}
      </div>
    );
  }

  public render(): ReactNode {
    const formName = "customer-venue-details-edit-form";

    return (
      <Form data-test={formName} id={formName}>
        <Row>
          <Col className="modal__col-mb">
            <Form.Group>
              <Form.Label className="mb-2" htmlFor={`${formName}-venue-name`}>
                {LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.name)}
              </Form.Label>
              <div id={`${formName}-venue-name-container`}>
                <Form.Control
                  id={`${formName}-venue-name`}
                  data-test="venue-name-text-input"
                  name="name"
                  onChange={this.onChange}
                  disabled={false}
                  value={this.state.name}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="modal__col-mb">
            <Form.Group>
              <CheckBox
                name="group-floors-checkbox"
                header={LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.wizard.step_1.groupbyfloors)}
                description={LanguageProvider.t(
                  TranslationMapper.pages.customer_venue_edit.wizard.step_1.groupbyfloorssublabel
                )}
                onChange={this.handleGroupByFloorsChange}
                value={this.state.groupByFloors}
                id="group-floors-checkbox"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <CheckBox
                name="nfc-scan-required-checkbox"
                className="mb-2"
                header={LanguageProvider.t(
                  TranslationMapper.pages.customer_venue_edit.wizard.step_1.nfc_required.label
                )}
                description={LanguageProvider.t(
                  TranslationMapper.pages.customer_venue_edit.wizard.step_1.nfc_required.venue
                )}
                onChange={this.setScanningRequired}
                value={this.state.isCheckInRequired} // venue
                id="nfc-scan-required-checkbox"
              />
              <CheckBox
                name="nfc-required-floors-checkbox"
                description={LanguageProvider.t(
                  TranslationMapper.pages.customer_venue_edit.wizard.step_1.nfc_required.floor
                )}
                onChange={this.handleNfcRequiredForFloorsChange}
                value={this.state.isFloorNFCRequired}
                id="nfc-required-floors-checkbox"
                disabled={!this.state.groupByFloors}
              />
            </Form.Group>
          </Col>
        </Row>
        {this.renderErrorMessage}
      </Form>
    );
  }
}

const wizardStep: IWizardStep = {
  form: CustomerVenueDetailsStep,
  titleResource: "",
  name: ICustomerVenueWizardStepsEnum.Step1,
};

export default wizardStep;
