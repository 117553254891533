import { Button } from "components/material/buttons/buttons";
import { IWizardStepOnChangeEvent } from "components/wizard/interfaces/IWizardStepOnChange";
import ObjectExistsStatus from "enums/objectExistsStatus";
import { Guid } from "guid-typescript";
import TranslationMapper from "i18n/mapper";
import CustomerVenueRequest from "models/customerVenueRequest";
import LanguageProvider from "providers/languageProvider";
import * as React from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  emptyCustomerVenue,
  emptyRequiredLocationFloorNFCListAction,
  getCustomerVenue,
  getRequiredLocationFloorNFCList,
  setGroupByFloors,
  setIsCheckInRequired,
  setSelectedVenueIsFloorNfcRequired,
} from "store/actions/locationActions";
import { RootState } from "store/reducers/rootReducer";
import Dictionary from "utils/dictionary";

import LoaderTypes from "../../enums/loaderTypes";
import { getVenueExists, updateVenue } from "../../store/actions/customerActions";
import Loader from "../loader/loader";
import IWizardStep from "../wizard/interfaces/IWizardStep";
import CustomerFloorNfcTagStep from "./customerFloorNfcTagStep";
import CustomerVenueDetailsStep from "./customerVenueDetailsStep";
import CustomerVenueNfcTagsStep from "./customerVenueNfcTagsStep";
import CustomerVenueValidator from "./customerVenueValidator";
import ICustomerVenueWizardProps, {
  ICustomerVenueWizardDispatchProps,
  ICustomerVenueWizardStateProps,
} from "./interfaces/ICustomerVenueWizardProps";
import ICustomerVenueWizardState from "./interfaces/ICustomerVenueWizardState";
import IRequiredFloorNFCTagObject from "./interfaces/IRequiredFloorNFCTagObject";

class CustomerVenueWizard extends React.Component<ICustomerVenueWizardProps, ICustomerVenueWizardState> {
  private readonly wizardSteps: IWizardStep[] = [
    CustomerVenueDetailsStep,
    CustomerVenueNfcTagsStep,
    CustomerFloorNfcTagStep,
  ];

  public constructor(props: ICustomerVenueWizardProps) {
    super(props);

    const isValid = new Dictionary<boolean>();

    for (let index = 0; index < this.wizardSteps.length; index++) {
      isValid.add(index.toString(), false); // by default all steps contain invalid data
    }

    const state: ICustomerVenueWizardState = {
      customerVenueStatus: ObjectExistsStatus.IsNew,
      activeStepIndex: 0,
      isInEditModus: false,
      isValid: isValid,
      numberOfSteps: 2,
    };

    this.state = state;
    this.goToNextStep = this.goToNextStep.bind(this);
    this.goToPreviousStep = this.goToPreviousStep.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount(): void {
    if (this.props.selectedVenueId != null) {
      this.props.onGetVenue(this.props.customer.id, this.props.selectedVenueId);
    }
  }

  public componentDidUpdate(
    prevProps: ICustomerVenueWizardProps,
    prevState: Readonly<ICustomerVenueWizardState>
  ): void {
    if (prevProps.selectedVenue !== this.props.selectedVenue && this.props.selectedVenue) {
      this.setState(
        {
          details: {
            id: this.props.selectedVenue.id,
            name: this.props.selectedVenue.name,
            externalVenueId: this.props.selectedVenue.externalVenueId,
            customerId: this.props.selectedVenue.customerId,
            groupByFloors: this.props.selectedVenue.groupByFloors,
            isFloorNFCRequired: this.props.selectedVenue.isFloorNFCRequired ?? false,
            isCheckInRequired: this.props.selectedVenue.isCheckInRequired ?? false,
          },
          nfcTagDetails: {
            nfcTags: this.props.selectedVenue.nfcTags ?? [],
          },
          customerFloorNfcTagsState: {
            requiredFloorNFCTagObject: this.props.requiredLocationFloorNFCList, // mag dit weg?
            isFloorNFCRequired: this.props.selectedVenue.isFloorNFCRequired ?? false,
          },
          isInEditModus: true,
        },
        this.validateSteps
      );
    }
    if (
      prevProps.requiredLocationFloorNFCList !== this.props.requiredLocationFloorNFCList &&
      this.props?.selectedVenue?.isFloorNFCRequired &&
      this.props?.requiredLocationFloorNFCList?.requiredFloorNFCTags
    ) {
      if (this.props.requiredLocationFloorNFCList?.requiredFloorNFCTags.length === 0) {
        return;
      }
      const numberOfSteps = this.numberOfSteps(
        this.props.selectedVenue?.isFloorNFCRequired ?? false,
        this.wizardSteps.length,
        this.props.requiredLocationFloorNFCList
      );

      this.setState({
        numberOfSteps: numberOfSteps,
      });
    }
  }

  private get modalHook(): HTMLElement {
    let modalHook = document.getElementById("modal");
    if (!modalHook) {
      modalHook = document.createElement("div");
      modalHook.setAttribute("id", "portal");
      document.body.appendChild(modalHook);
    }

    return modalHook;
  }

  private saveForm(checkVenueExists: boolean): void {
    if (this.state.details == null) {
      return;
    }

    const venue = new CustomerVenueRequest(
      this.state.details,
      this.state.nfcTagDetails,
      this.state.customerFloorNfcTagsState?.requiredFloorNFCTagObject?.requiredFloorNFCTags,
      this.props.selectedVenueId
    );
    if (venue.externalVenueId == null || venue.externalVenueId === "") {
      venue.externalVenueId = Guid.create().toString();
    }

    if (checkVenueExists) {
      this.props.onUpsertVenue(this.props.customer.id, venue, () => this.closeModal());
      return;
    }

    // upsert floor nfc's if exists
    this.props.onUpsertVenue(venue.customerId, venue);
    this.closeModal();
  }

  private closeModal(): void {
    this.props.onEmptyRequiredLocationFloorNFCListAction();
    this.props.onEmptyCustomerVenue();
    this.setState({}, () => this.props.onClose());
  }

  private get isCurrentStepValid(): boolean {
    let isActiveStepIndex = false,
      isCustomerVenueAllowed = false;

    if (this.state.isValid.item(this.state.activeStepIndex.toString())) {
      isActiveStepIndex = true;
    }

    if (
      this.state.customerVenueStatus === ObjectExistsStatus.IsNew ||
      (this.state.details !== null &&
        this.state.customerVenueStatus === ObjectExistsStatus.IsDeleted &&
        this.state.details?.reactivateBuilding)
    ) {
      isCustomerVenueAllowed = true;
    }

    return isActiveStepIndex && isCustomerVenueAllowed;
  }

  private get currentStep(): number {
    return this.state.activeStepIndex + 1;
  }

  private get showNextButton(): boolean {
    return this.state.activeStepIndex < this.state.numberOfSteps - 1;
  }

  private get showSaveButton(): boolean {
    return this.state.activeStepIndex === this.state.numberOfSteps - 1;
  }

  private get showPreviousButton(): boolean {
    return this.state.activeStepIndex > 0;
  }

  // put the right state in the wizardstep
  private getStepValue(): any {
    switch (this.state.activeStepIndex) {
      case 0: //wizard step 1
        return this.state.details;
      case 1: //wizard step 2
        return this.state.nfcTagDetails;
      case 2: //wizard step 3
        return this.state.customerFloorNfcTagsState;
    }
  }

  private goToNextStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep < this.state.numberOfSteps - 1) {
      this.setState({
        activeStepIndex: currentStep + 1,
      });
    }
  }

  private goToPreviousStep(): void {
    const currentStep = this.state.activeStepIndex;

    if (currentStep > 0) {
      this.setState({
        activeStepIndex: currentStep - 1,
      });
    }
  }

  public onChange(event: IWizardStepOnChangeEvent): void {
    const name = event.target.name;
    const value = event.target.value;

    // if floorNFC is required, check if floorNfc must be added
    if (value.isFloorNFCRequired !== undefined && value.isFloorNFCRequired !== this.state.details?.isFloorNFCRequired) {
      // set isFloorNFCRequired in Redux
      this.props.onSetFloorNFCRequired(value.isFloorNFCRequired);

      if (value.isFloorNFCRequired) {
        this.props.onGetRequiredLocationFloorNFCList(
          this.props.customer.id,
          this.props.selectedVenue?.externalVenueId ?? ""
        );

        const numberOfSteps = this.numberOfSteps(
          value.isFloorNFCRequired,
          this.wizardSteps.length,
          this.props.requiredLocationFloorNFCList ?? {}
        );
        this.setState({
          numberOfSteps: numberOfSteps,
        });
      } else {
        const numberOfSteps = this.numberOfSteps(value.isFloorNFCRequired, this.wizardSteps.length, {});
        this.setState({
          numberOfSteps: numberOfSteps,
        });
      }
    }

    // set isCheckInRequired in Redux
    if (value.isCheckInRequired !== undefined && value.isCheckInRequired !== this.state.details?.isCheckInRequired) {
      this.props.onIsCheckInRequired(value.isCheckInRequired);
    }

    // set GroupByFloors in Redux
    if (value.groupByFloors !== undefined && value.groupByFloors !== this.state.details?.groupByFloors) {
      this.props.onGroupByFloors(value.groupByFloors);
    }

    // shouldReactivateBuilding
    if (value.externalVenueId !== undefined && value.externalVenueId !== this.state.details?.externalVenueId) {
      this.setState(
        current => ({ ...current, [name]: value, customerVenueStatus: ObjectExistsStatus.IsNew }),
        this.validateSteps
      );
      return;
    }

    this.setState(current => ({ ...current, [name]: value }), this.validateSteps);
  }

  private validateSteps(): void {
    this.setState({
      isValid: this.validationStatusPerStep,
    });
  }

  private get validationStatusPerStep(): Dictionary<boolean> {
    const isValidDictionary = new Dictionary<boolean>();

    isValidDictionary.add("0", this.state.details ? CustomerVenueValidator.areDetailsValid(this.state.details) : false);
    isValidDictionary.add(
      "1",
      CustomerVenueValidator.areNfcTagsValid(
        this.state.details?.isCheckInRequired ?? false, //venue
        this.state.nfcTagDetails?.nfcTags ?? []
      )
    );

    if (this.state.details?.isFloorNFCRequired) {
      const missingNfcTags = this.state.customerFloorNfcTagsState?.requiredFloorNFCTagObject?.requiredFloorNFCTags;
      const missingNfcTagsArray =
        missingNfcTags !== undefined ? (missingNfcTags.map(x => x.checkInCode ?? "") as string[]) : [];
      isValidDictionary.add(
        "2",
        CustomerVenueValidator.areNfcTagsValid(this.state.details?.isFloorNFCRequired ?? false, missingNfcTagsArray)
      );
    }
    return isValidDictionary;
  }

  private numberOfSteps(
    isFloorNFCRequired: boolean,
    wizardStepLength: number,
    requiredFloorNFCTagObject?: IRequiredFloorNFCTagObject
  ): number {
    if (
      isFloorNFCRequired &&
      requiredFloorNFCTagObject?.requiredFloorNFCTags &&
      requiredFloorNFCTagObject?.requiredFloorNFCTags?.length > 0
    ) {
      return wizardStepLength;
    }
    return wizardStepLength - 1;
  }

  public render(): React.ReactNode {
    const wizardStep = this.wizardSteps[this.state.activeStepIndex];
    return (
      <>
        {this.modalHook &&
          ReactDOM.createPortal(
            <Modal
              backdrop="static"
              show={true}
              onHide={this.closeModal}
              dialogClassName="modal__customer-venue"
              centered
            >
              <Modal.Header closeButton>
                <div className="modal-header__info">
                  <div>
                    <h1 className="modal-title">
                      {LanguageProvider.t(TranslationMapper.pages.customer_venue_edit.edit_title)}
                    </h1>
                    {wizardStep.subtitleResource && (
                      <h5 className="modal-title">{LanguageProvider.t(wizardStep.subtitleResource)}</h5>
                    )}
                  </div>
                  <div className="modal__steps" data-testid="create-venue-wizard-steps">
                    {this.currentStep}/{this.state.numberOfSteps}
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body__height-md">
                {!this.props.isLoading && (
                  <wizardStep.form
                    onChange={this.onChange}
                    name={wizardStep.name}
                    value={this.getStepValue()}
                    customer={this.props.customer}
                    isInEditModus={this.state.isInEditModus}
                    numberOfSteps={this.state.numberOfSteps}
                    customerVenueStatus={this.state.customerVenueStatus}
                    customerVenue={this.state.details} // step 1
                    customerFloorNfcTagsState={this.state.customerFloorNfcTagsState} //step 3
                  />
                )}
                <div className="loader">
                  <Loader isLoading={this.props.isLoading} />
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <div className="d-flex flex-row">
                  <Button
                    className="btn-outline-secondary me-2"
                    onClick={this.closeModal}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.cancel)}
                    iconEnd="times"
                  />
                  {this.showPreviousButton && (
                    <Button
                      className="btn-outline-secondary"
                      onClick={this.goToPreviousStep}
                      resourceLabel={LanguageProvider.t(TranslationMapper.buttons.previous)}
                      iconStart="arrow-left"
                    />
                  )}
                </div>
                {this.showNextButton && (
                  <Button
                    className={`${!this.showSaveButton ? "btn-primary" : ""}`}
                    disabled={!this.isCurrentStepValid}
                    onClick={this.goToNextStep}
                    resourceLabel={LanguageProvider.t(TranslationMapper.buttons.next)}
                    iconEnd="arrow-right"
                  />
                )}
                {this.showSaveButton && (
                  <Button
                    className="btn-primary"
                    disabled={!this.isCurrentStepValid}
                    onClick={(): void => this.saveForm(!this.state.isInEditModus)}
                    resourceLabel={LanguageProvider.t(
                      this.state.customerVenueStatus === ObjectExistsStatus.IsDeleted
                        ? TranslationMapper.buttons.activate
                        : TranslationMapper.buttons.save_and_close
                    )}
                    iconEnd="floppy-disk"
                  />
                )}
              </Modal.Footer>
            </Modal>,
            this.modalHook
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ICustomerVenueWizardStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.SelectedVenue),
  selectedVenue: state.locationState.selectedVenue,
  requiredLocationFloorNFCList: state.locationState.requiredFloorNFCTagObject ?? {},
});

const mapDispatchToProps: ICustomerVenueWizardDispatchProps = {
  onGetVenue: getCustomerVenue,
  onEmptyCustomerVenue: emptyCustomerVenue,
  onGetVenueExists: getVenueExists,
  onGetRequiredLocationFloorNFCList: getRequiredLocationFloorNFCList,
  onEmptyRequiredLocationFloorNFCListAction: emptyRequiredLocationFloorNFCListAction,
  onUpsertVenue: updateVenue,
  onSetFloorNFCRequired: setSelectedVenueIsFloorNfcRequired,
  onIsCheckInRequired: setIsCheckInRequired,
  onGroupByFloors: setGroupByFloors,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerVenueWizard);
