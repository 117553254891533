import * as msal from "@azure/msal-browser";

import CleaningUserAuthenticator from "../authenticators/cleaningUserAuthenticator";
import CleaningManagementService from "./cleaningManagementService";

export default class AccessService {
  private readonly userAuthenticator: CleaningUserAuthenticator;

  private readonly cleaningManagementService: CleaningManagementService;

  public constructor() {
    this.userAuthenticator = new CleaningUserAuthenticator();
    this.cleaningManagementService = new CleaningManagementService();
  }

  public ensureAccessAsync(successCallBack: () => void, errorCallBack: () => void): void {
    this.userAuthenticator
      .authenticateUserAsync()
      .then(async (user: msal.AccountInfo | null) => {
        if (user) {
          const hasAccess = await this.cleaningManagementService.ensureAccess();
          if (hasAccess) {
            successCallBack();
            return;
          }
        }
        errorCallBack();
      })
      .catch((_exc) => {
        errorCallBack();
      });
  }
}
