import IIncidentType from "../../interfaces/IIncidentType";
import CleaningManagementService from "../../services/cleaningManagementService";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const fetchedCustomerIncidentTypes = actionCreator<
  ActionTypes.FETCHED_CUSTOMER_INCIDENT_TYPES,
  { customerId: string; incidentTypes: IIncidentType[] }
>(ActionTypes.FETCHED_CUSTOMER_INCIDENT_TYPES);

const cleaningManagementService = new CleaningManagementService();

export function getCustomerIncidentTypes(customerId: string): AppAction {
  return (dispatch): void => {
    cleaningManagementService.getActiveNotificationCategoriesForCustomer(customerId).then((notificationCategories) => {
      const incidentTypes: IIncidentType[] = notificationCategories.map((category) => {
        const activeImageUri = `${category.imageUri}${"_Notificationcategory_active"}`;
        const inActiveImageUri = `${category.imageUri}${"_Notificationcategory_inactive"}`;

        return {
          id: category.id,
          icons: {
            active: activeImageUri,
            inActive: inActiveImageUri,
            activeImageExists: category.activeImageExists,
            inactiveImageExists: category.inactiveImageExists,
          },
          label: category.name,
        } as IIncidentType;
      });

      dispatch(fetchedCustomerIncidentTypes({ customerId, incidentTypes }));
    });
  };
}
