import LoaderTypes from "../../enums/loaderTypes";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const startLoading = actionCreator<ActionTypes.START_LOADING, LoaderTypes>(ActionTypes.START_LOADING);
export const finishLoading = actionCreator<ActionTypes.FINISH_LOADING, LoaderTypes>(ActionTypes.FINISH_LOADING);

export function setAsLoading(loader: LoaderTypes): AppAction {
  return (dispatch): void => {
    dispatch(startLoading(loader));
  };
}

export function unsetAsLoading(loader: LoaderTypes): AppAction {
  return (dispatch): void => {
    dispatch(finishLoading(loader));
  };
}
