import CleaningCategory from "enums/cleaningCategory";
import i18next from "i18next";
import IActivityPost from "interfaces/IActivityPost";
import INotificationCategory from "interfaces/INotificationCategory";
import INotificationCategoryPost from "interfaces/INotificationCategoryPost";
import { cloneDeep } from "lodash";
import IActivityWizard from "pages/masterdata/components/activities/interfaces/IActivityWizard";
import INotificationCategoryDetails from "pages/masterdata/components/notificationCategories/interfaces/INotificationCategoryDetails";
import ImageUriProvider from "providers/imageUriProvider";
import { NotificationManager } from "react-notifications";
import { ActionTypes } from "store/actionTypes";

import LoaderTypes from "../../enums/loaderTypes";
import TranslationMapper from "../../i18n/mapper";
import IActivity from "../../interfaces/IActivity";
import CleaningManagementService from "../../services/cleaningManagementService";
import { actionCreator, AppAction } from "../appAction";
import { getCustomers } from "./customerActions";
import { startLoading, unsetAsLoading } from "./loaderActions";

export const fetchedActivities = actionCreator<ActionTypes.FETCHED_ACTIVITIES, IActivity[]>(
  ActionTypes.FETCHED_ACTIVITIES
);
export const fetchedSelectedActivity = actionCreator<
  ActionTypes.FETCHED_SELECTED_ACTIVITY,
  IActivityWizard | undefined
>(ActionTypes.FETCHED_SELECTED_ACTIVITY);
export const fetchedNotificationCategories = actionCreator<
  ActionTypes.FETCHED_NOTIFICATION_CATEGORIES,
  INotificationCategory[]
>(ActionTypes.FETCHED_NOTIFICATION_CATEGORIES);
export const fetchedSelectedNotificationCategory = actionCreator<
  ActionTypes.FETCHED_SELECTED_NOTIFICATION_CATEGORY,
  INotificationCategoryDetails | undefined
>(ActionTypes.FETCHED_SELECTED_NOTIFICATION_CATEGORY);

export function getMasterDataActivities(): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const getActivityLoader = LoaderTypes.Activities;

    dispatch(startLoading(getActivityLoader));

    cleaningManagementService
      .getApplicationActivities(true)
      .then((activities: IActivity[]) => {
        dispatch(fetchedActivities(activities));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.saveerror)))
      .finally(() => dispatch(unsetAsLoading(getActivityLoader)));
  };
}

export function getActivity(activityId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(startLoading(LoaderTypes.SelectedActivity));

    cleaningManagementService
      .getActivityById(activityId)
      .then((activity: IActivity) => {
        const activeString = ImageUriProvider.getActiveImageUri(CleaningCategory.Activity, true);
        const inActiveString = ImageUriProvider.getActiveImageUri(CleaningCategory.Activity, false);

        const foundActivity = {
          ...activity,
          feedbackRequests: cloneDeep(activity.feedbackRequests),
          activeImageUrl: activity.imageUri ? `${activity.imageUri}${activeString}?${new Date().getTime()}` : null,
          inactiveImageUrl: activity.imageUri ? `${activity.imageUri}${inActiveString}?${new Date().getTime()}` : null,
        } as IActivityWizard;

        dispatch(fetchedSelectedActivity(foundActivity));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.saveerror)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.SelectedActivity)));
  };
}

export function upsertActivity(activity: IActivityPost): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(startLoading(LoaderTypes.Activities));

    cleaningManagementService
      .upsertActivity(activity)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.saving));
        dispatch(getMasterDataActivities());
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.saveerror)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.Activities)));
  };
}

export function deleteActivity(activityId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    const deleteRouteLoader = LoaderTypes.Activities;

    dispatch(startLoading(deleteRouteLoader));

    cleaningManagementService
      .deleteApplicationActivity(activityId)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.succesfullydeleted));
        dispatch(getMasterDataActivities());
        dispatch(getCustomers());
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.deleteerror)))
      .finally(() => dispatch(unsetAsLoading(deleteRouteLoader)));
  };
}

export function resetActivity(): AppAction {
  return (dispatch): void => {
    dispatch(fetchedSelectedActivity(undefined));
  };
}

export function getNotificationCategories(): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();

    dispatch(startLoading(LoaderTypes.NotificationCategory));

    cleaningManagementService
      .getNotificationCategories()
      .then((notificationcategories: INotificationCategory[]) => {
        dispatch(fetchedNotificationCategories(notificationcategories));
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.saveerror)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.NotificationCategory)));
  };
}

export function clearNotificationCategories(): AppAction {
  return (dispatch): void => {
    dispatch(fetchedNotificationCategories([]));
  };
}

export function getNotificationCategory(notificationCategoryId: string): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch, getState) => {
    const loader = LoaderTypes.SelectedNotificationCategory;

    dispatch(startLoading(loader));

    const notificationCategory = getState().notificationState.notificationCategories?.find(
      (x) => x.id === notificationCategoryId
    );

    if (notificationCategory != null) {
      const activeString = ImageUriProvider.getActiveImageUri(CleaningCategory.NotificationCategory, true);
      const inActiveString = ImageUriProvider.getActiveImageUri(CleaningCategory.NotificationCategory, false);
      const cleaningString = ImageUriProvider.getNotificationCleaningUri();

      const updatedNotificationCategoryDetails = {
        id: notificationCategory.id,
        name: notificationCategory.name,
        imageUri: notificationCategory.imageUri,
        cleaningImageUrl: `${notificationCategory.imageUri}${cleaningString}?${new Date().getTime()}`,
        activeImageUrl: `${notificationCategory.imageUri}${activeString}?${new Date().getTime()}`,
        inactiveImageUrl: `${notificationCategory.imageUri}${inActiveString}?${new Date().getTime()}`,
      } as INotificationCategoryDetails;

      dispatch(fetchedSelectedNotificationCategory({ ...updatedNotificationCategoryDetails }));
    } else {
      NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.saveerror));
    }

    dispatch(unsetAsLoading(loader));
  };
}

export function upsertNotificationCategory(notificationCategory: INotificationCategoryPost): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(startLoading(LoaderTypes.NotificationCategory));

    cleaningManagementService
      .upsertNotificationCategory(notificationCategory)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.saving));
        dispatch(getNotificationCategories());
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.saveerror)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.NotificationCategory)));
  };
}

export function deleteNotificationCategory(notificationCategoryId: string): AppAction {
  return (dispatch): void => {
    const cleaningManagementService = new CleaningManagementService();
    dispatch(startLoading(LoaderTypes.NotificationCategory));
    dispatch(clearNotificationCategories());

    cleaningManagementService
      .deleteNotificationCategory(notificationCategoryId)
      .then(() => {
        NotificationManager.success(i18next.t(TranslationMapper.pages.masterdata.succesfullydeleted));
        dispatch(fetchedSelectedNotificationCategory(undefined));
        dispatch(getNotificationCategories());
      })
      .catch(() => NotificationManager.error(i18next.t(TranslationMapper.pages.masterdata.deleteerror)))
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.NotificationCategory)));
  };
}

export function resetNotificationCategory(): AppAction {
  return (dispatch): void => {
    dispatch(fetchedSelectedNotificationCategory(undefined));
  };
}
