import IRequiredFloorNFCTags from "components/customerVenueWizard/interfaces/IRequiredFloorNFCTagObject";
import ActivityStatus from "enums/activityStatus";
import ObjectExistsStatus from "enums/objectExistsStatus";

import { CmApiAuthenticator } from "../authenticators/cmApiAuthenticator";
import IRoute from "../components/routeWizard/interfaces/IRoute";
import { ApplicationConfig } from "../config";
import IActivity from "../interfaces/IActivity";
import IActivityAction from "../interfaces/IActivityAction";
import IActivityActionFeedbackDetails from "../interfaces/IActivityActionFeedbackDetails";
import IActivityActionsWrapper from "../interfaces/IActivityActionsWrapper";
import IActivityFilter from "../interfaces/IActivityFilter";
import IActivityPost from "../interfaces/IActivityPost";
import ICleaningPlanningOverview from "../interfaces/ICleaningPlanningOverview";
import ICustomer from "../interfaces/ICustomer";
import ICustomerActivity from "../interfaces/ICustomerActivity";
import ICustomerProspect from "../interfaces/ICustomerProspect";
import ICustomerVenue from "../interfaces/ICustomerVenue";
import IExpandedRouteLogging from "../interfaces/IExpandedRouteLogging";
import INotificationCategory from "../interfaces/INotificationCategory";
import INotificationCategoryPost from "../interfaces/INotificationCategoryPost";
import ISimpleNotification from "../interfaces/INotificationOverview";
import INotificationReactionPost from "../interfaces/INotificationReactionPost";
import INotificationReactionResponse from "../interfaces/INotificationReactionResponse";
import INotification from "../interfaces/INotificationResponse";
import IOperator from "../interfaces/IOperator";
import ISimpleRouteLogging from "../interfaces/ISimpleRouteLogging";
import IVenue from "../interfaces/IVenue";
import ICustomerNotificationCategoryRequest from "../interfaces/request/ICustomerNotificationCategoryRequest";
import ICustomerNotificationCategoryResponse from "../interfaces/response/ICustomerNotificationCategoryResponse";
import RouteRequest from "../models/routeRequest";
import BaseService from "./baseService";

export default class CleaningManagementService extends BaseService {
  public constructor() {
    super(ApplicationConfig.endpoints.winvisionCleaningManagementApi, new CmApiAuthenticator());
  }

  public ensureAccess(): Promise<boolean> {
    return this.get<boolean>("managementaccess").catch((e) => false);
  }

  public async getApplicationActivities(includeFeedback?: boolean): Promise<IActivity[]> {
    return this.get<IActivity[]>(`MasterData/activities/${includeFeedback ?? ""}`);
  }

  public async getActivityById(activityId: string): Promise<IActivity> {
    return this.get<IActivity>(`MasterData/activities/${activityId}`);
  }

  public async upsertActivity(activity: IActivityPost): Promise<void> {
    await this.put(`MasterData/activities`, activity);
  }

  public async deleteApplicationActivity(activityId: string): Promise<void> {
    await this.delete(`MasterData/activities/${activityId}`);
  }

  public async getCustomerActivityActions(
    customerId: string,
    activityFilter: IActivityFilter
  ): Promise<IActivityActionsWrapper> {
    const scheduled = activityFilter.statuses.every((status) => status === ActivityStatus.Scheduled);
    return scheduled
      ? this.postWithResponse<IActivityActionsWrapper>(`customers/${customerId}/scheduledactivities`, activityFilter)
      : this.postWithResponse<IActivityActionsWrapper>(`customers/${customerId}/loggedactivities`, activityFilter);
  }

  public async getActivityFeedbackRequests(activityId: string): Promise<IActivityActionFeedbackDetails[]> {
    return this.get<IActivityActionFeedbackDetails[]>(`activity/${activityId}/feedbackrequests`);
  }

  public async getCustomerVenueOperators(customerVenueId: string): Promise<IOperator[]> {
    return this.get<IOperator[]>(`location/${customerVenueId}/operators`);
  }

  public async updateCustomerActivityActions(customerId: string, activityActions: IActivityAction[]): Promise<void> {
    await this.put(`customers/${customerId}/finishactivities`, activityActions);
  }

  public async updateCustomerActivityActionsOperator(
    customerId: string,
    activityActions: IActivityAction[]
  ): Promise<void> {
    await this.put(`customers/${customerId}/updateactivityoperator`, activityActions);
  }

  public async getNotificationCategories(): Promise<INotificationCategory[]> {
    return this.get<INotificationCategory[]>(`MasterData/notificationcategories`);
  }

  public async upsertNotificationCategory(notificationCategory: INotificationCategoryPost): Promise<void> {
    await this.put(`MasterData/notificationcategories`, notificationCategory);
  }

  public async deleteNotificationCategory(notificationCategoryId: string, forced: boolean = false): Promise<Response> {
    return this.delete(
      `MasterData/notificationcategories/${notificationCategoryId}?forced=${forced ? "true" : "false"}`
    );
  }

  public async getCustomerRoutes(customerId: string): Promise<ICleaningPlanningOverview[]> {
    return this.get<ICleaningPlanningOverview[]>(`customers/${customerId}/routes`);
  }

  public async getRouteById(routeId: string): Promise<IRoute> {
    return this.get<IRoute>(`Routes/${routeId}`);
  }

  public async upsertRouteFromWizard(route: RouteRequest): Promise<Response> {
    return this.createRouteFromWizard(route);
  }

  private async createRouteFromWizard(route: RouteRequest): Promise<Response> {
    return this.post(`routes`, route);
  }

  public async deactivateRoute(id: string): Promise<void> {
    await this.delete(`Routes/${id}`);
  }

  public async getCustomerProspects(source: string): Promise<ICustomerProspect[]> {
    return this.get<ICustomerProspect[]>(`customers/prospects?source=${source}`).catch((_) => []);
  }

  public async getCustomerOverview(): Promise<ICustomer[]> {
    return this.get<ICustomer[]>(`customers`).catch((_) => []);
  }

  public async getCustomer(id: string): Promise<ICustomer> {
    return this.get<ICustomer>(`customers/${id}`);
  }

  public async checkCustomerExists(name: string, externalCustomerId: string): Promise<ObjectExistsStatus> {
    return this.get<ObjectExistsStatus>(`customers/exists?name=${name}&externalCustomerId=${externalCustomerId}`);
  }

  public async upsertCustomer(customer: ICustomer): Promise<Response> {
    return this.post(`customers/`, customer);
  }

  public async deleteCustomer(id: string): Promise<void> {
    await this.delete(`customers/${id}`);
  }

  public async getCustomerActivityTypes(id: string): Promise<ICustomerActivity[]> {
    return this.get<ICustomerActivity[]>(`Customers/${id}/activitytypes`);
  }

  public async getCustomerVenueByExternalVenueId(customerId: string, externalVenueId: string): Promise<ICustomerVenue> {
    return this.get<ICustomerVenue>(`Customers/${customerId}/externalVenue/${externalVenueId}`);
  }

  public async checkCustomerVenueExists(customerId: string, externalVenueId: string): Promise<ObjectExistsStatus> {
    return this.get<ObjectExistsStatus>(`Customers/${customerId}/externalVenue/${externalVenueId}/exists`);
  }

  public async getCustomerVenue(customerId: string, venueId: string): Promise<ICustomerVenue> {
    return this.get<ICustomerVenue>(`Customers/${customerId}/venues/${venueId}`);
  }

  public async getRequiredLocationFloorNFCList(
    customerId: string,
    externalVenueId: string
  ): Promise<IRequiredFloorNFCTags> {
    return this.get<IRequiredFloorNFCTags>(
      `CustomerLocation/${customerId}/ListAllRequiredFloorNFCTags?locationId=${externalVenueId}`
    );
  }

  public async upsertCustomerVenue(customerId: string, customerVenue: ICustomerVenue): Promise<Response> {
    return this.post(`customers/${customerId}/venues/`, customerVenue);
  }

  public async deleteCustomerVenue(customerId: string, venueId: string): Promise<void> {
    await this.delete(`Customers/${customerId}/venues/${venueId}`);
  }

  public async getCustomerNotificationCategories(id: string): Promise<ICustomerNotificationCategoryResponse[]> {
    return this.get<ICustomerNotificationCategoryResponse[]>(`Customers/${id}/notificationcategoriesold`);
  }

  public async getActiveNotificationCategoriesForCustomer(id: string): Promise<INotificationCategory[]> {
    return this.get<INotificationCategory[]>(`Customers/${id}/notificationcategories`);
  }

  public async getCustomerOperators(id: string): Promise<IOperator[]> {
    return this.get<IOperator[]>(`Customers/${id}/operators`);
  }

  public async getBasicCustomerNotifications(
    id: string,
    fromDate: string,
    toDate: string
  ): Promise<ISimpleNotification[]> {
    return this.get<ISimpleNotification[]>(
      `Notifications/bydate?customerId=${id}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  public async getCustomerNotifications(ids: string[]): Promise<INotification[]> {
    return this.postWithResponse<INotification[]>(`Notifications/export`, { notificationIds: ids });
  }

  public async getCustomerLocations(customerId: string): Promise<IVenue[]> {
    return this.get<IVenue[]>(`Customers/${customerId}/venues`);
  }

  public async postNotificationReaction(
    notificationId: string,
    notificationReaction: INotificationReactionPost
  ): Promise<INotificationReactionResponse> {
    return this.postWithResponse<INotificationReactionResponse>(
      `Notifications/${notificationId}/reaction`,
      notificationReaction
    );
  }

  public async updateNotificationStatus(notificationId: string, notificationStatus: string): Promise<Response> {
    return this.post(`Notifications/updatestatus/${notificationId}`, notificationStatus);
  }

  public async updateCustomerNotificationCategories(
    customerId: string,
    customerNotificationCategories: ICustomerNotificationCategoryRequest[]
  ): Promise<void> {
    await this.post(`Customers/${customerId}/notificationcategories`, customerNotificationCategories);
  }

  public async disableCustomerNotificationCategory(customerId: string, categoryId: string): Promise<void> {
    await this.delete(`Customers/${customerId}/notificationcategories/${categoryId}`);
  }

  public async updateCustomerActivities(customerId: string, activityIds: string[]): Promise<void> {
    await this.post(`Customers/${customerId}/activities`, activityIds);
  }

  public async getCustomerLogging(
    customerId: string,
    fromDate: string,
    toDate: string
  ): Promise<ISimpleRouteLogging[]> {
    return this.get<ISimpleRouteLogging[]>(`Logging?customerId=${customerId}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  public async getCustomerLogData(routeDataId: string): Promise<IExpandedRouteLogging> {
    return this.get<IExpandedRouteLogging>(`Logging/${routeDataId}`);
  }

  public async getLoggingForExport(routeDataIdsCommaSeparated: string): Promise<IExpandedRouteLogging[]> {
    return this.get<IExpandedRouteLogging[]>(`Logging/routeDataIdList?routeDataIds=${routeDataIdsCommaSeparated}`);
  }

  public async getDeletePermission(): Promise<boolean> {
    return this.get<boolean>("Logging/hasDeletePermission").catch((e) => false);
  }

  public async deleteRouteData(routeDataIds: string[]): Promise<Response> {
    return this.post("Logging/delete", routeDataIds);
  }
}
