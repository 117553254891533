import * as msal from "@azure/msal-browser";

import { ApplicationConfig } from "../config";
import MsalFactory from "../factories/msalFactory";

export class MsalAuthenticator {
  protected async signInAsync(): Promise<msal.AccountInfo | null> {
    try {
      const account = await this.checkCacheForExistingAccountAsync();
      if (account == null) {
        MsalFactory.getMsalContext().loginRedirect({ scopes: ApplicationConfig.scopes.cmApi });
        return null;
      }
      return account;
    } catch (error) {
      // More about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      console.error(error);

      throw error;
    }
  }

  protected async acquireTokenAsync(scopes: string[]): Promise<string | null> {
    const account = this.getLoggedInAccount();
    if (account == null) {
      return null;
    }

    try {
      const silentRequest = {
        account: account,
        scopes: scopes,
        forceRefresh: false,
      };

      const result = await MsalFactory.getMsalContext().acquireTokenSilent(silentRequest);

      if (result == null || !result.accessToken) {
        throw Error();
      }

      return result.accessToken;
    } catch {
      const redirectRequest = {
        account: account,
        scopes: scopes,
        forceRefresh: false,
      };

      await MsalFactory.getMsalContext().acquireTokenRedirect(redirectRequest);
      return null;
    }
  }

  public async getTokenAsync(scopes: string[]): Promise<string> {
    const account = this.getLoggedInAccount();

    if (account == null) {
      return "";
    }

    const silentRequest = {
      scopes: scopes,
      forceRefresh: false,
      account: account,
    };

    const result = await this.acquireTokenAsync(silentRequest.scopes);
    if (!result) {
      throw Error();
    }
    return result;
  }

  private async checkCacheForExistingAccountAsync(): Promise<msal.AccountInfo | null> {
    return MsalFactory.getMsalContext()
      .handleRedirectPromise()
      .then((response) => {
        if (response != null) {
          return response.account;
        } else {
          return this.getLoggedInAccount();
        }
      });
  }

  private getLoggedInAccount(): msal.AccountInfo | null {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const allAccounts = MsalFactory.getMsalContext().getAllAccounts();
    if (allAccounts.length === 1) {
      return allAccounts[0];
    } else if (allAccounts.length > 1) {
      MsalFactory.logoutAllAccounts(null);
    }

    return null;
  }
}
