import GoToPage from "components/goToPage/goToPage";
import { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DesignSystemExclusiveTypes from "./interfaces/DesignSystemExclusiveTypeEnum";
import IDesignSystemExclusiveProps from "./interfaces/IDesignSystemExclusiveProps";
import IDesignSystemExclusiveState from "./interfaces/IDesignSystemExclusiveState";

export default class DesignSystemExclusive extends Component<IDesignSystemExclusiveProps, IDesignSystemExclusiveState> {
  public constructor(props: IDesignSystemExclusiveProps) {
    super(props);

    const state: IDesignSystemExclusiveState = {};

    this.state = state;
  }

  public renderHeader(): JSX.Element {
    return (
      <>
        {this.renderHeaderActions()}
        {this.renderHeaderSub()}
      </>
    );
  }

  public renderHeaderActions(): JSX.Element {
    const disabled = true;

    return (
      <header className="header-actions">
        {/* eslint-disable-next-line */}
        <a id="header-actions" style={{ scrollMarginTop: "11rem" }} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 header-actions__content">
              {/* Header with Title || Header with Title and Back btn */}
              <div className="d-flex align-items-center">
                <GoToPage titleResource="Back" page="#" />
                <div>
                  <h1>Page title</h1>
                  <h4>Page header</h4>
                </div>
              </div>

              {/* Dit gebruiken als er geen back button is
              <div>
                <h1>Page title</h1>
                <h4>Page header</h4>
              </div> */}
              <div className="header-actions__buttons">
                {/* upload */}
                <button className="btn btn-primary btn--rounded" disabled={disabled}>
                  <FontAwesomeIcon icon={["fal", "arrow-up-to-line"]} fixedWidth />
                </button>
                {/* download */}
                <button className="btn btn-primary btn--rounded" disabled={disabled}>
                  <FontAwesomeIcon icon={["fal", "arrow-down-to-line"]} fixedWidth />
                </button>
                {/* delete */}
                <button className="btn btn-primary btn--rounded" disabled={disabled}>
                  <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth />
                </button>
                {/* copy */}
                <button className="btn btn-primary btn--rounded" disabled={disabled}>
                  <FontAwesomeIcon icon={["fal", "copy"]} fixedWidth />
                </button>
                {/* edit */}
                <button className="btn btn-primary btn--rounded" disabled={disabled}>
                  <FontAwesomeIcon icon={["fal", "pen"]} fixedWidth />
                </button>
                {/* add */}
                <button className="btn btn-primary btn--rounded">
                  <FontAwesomeIcon icon={["fal", "plus"]} fixedWidth />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

  // Exclusive for Cleaning Management
  public renderHeaderSub(): JSX.Element {
    return (
      <header className="header-sub">
        {/* eslint-disable-next-line */}
        <a id="header-sub" style={{ scrollMarginTop: "11rem" }} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 header-sub__content">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a href="./design-system" className="nav-link active">
                    item 1
                  </a>
                </li>
                <li className="nav-item">
                  <a href="./design-system" className="nav-link">
                    item 2
                  </a>
                </li>
                <li className="nav-item">
                  <a href="./design-system" className="nav-link">
                    item 3
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }

  public renderButton(): JSX.Element {
    return (
      <>
        <hr />
        <h2 className="text-warning">No exclusive buttons</h2>
      </>
    );
  }

  public renderNav(): JSX.Element {
    return (
      <ul className="nav nav-pills">
        <li className="nav-item">
          <a href="#header-actions" className="nav-link">
            Header actions
          </a>
        </li>
        <li className="nav-item">
          <a href="#header-sub" className="nav-link">
            Header sub
          </a>
        </li>
      </ul>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.props.type === DesignSystemExclusiveTypes.HEADER && this.renderHeader()}
        {this.props.type === DesignSystemExclusiveTypes.BUTTON && this.renderButton()}
        {this.props.type === DesignSystemExclusiveTypes.NAV && this.renderNav()}
      </>
    );
  }
}
