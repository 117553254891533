import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-datepicker/dist/react-datepicker.css";
import "./i18n/config";
import "./main.scss";

import * as ReactDOM from "react-dom";
import { NotificationContainer } from "react-notifications";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { fetchInitialConfiguration } from "store/fetchInitialConfiguration";

import ReactNotification from "./components/reactNotification/reactNotification";
import ErrorBoundary from "./error/components/errorBoundary/errorBoundary";
import MsalFactory from "./factories/msalFactory";
import App from "./pages/app";
import FeatureFlagProvider from "./providers/featureFlagProvider";
import TelemetryProvider from "./providers/telemetryProvider";
import AccessService from "./services/accessService";
import StoreFactory from "./store/store";
import AppEventHub from "./utils/appEventHub";
import Loader from "components/loader/loader";

AppEventHub.initialize();
MsalFactory.initialize();
ReactNotification.manageNotifications();

const store = StoreFactory.Create();
store.subscribe(handleStoreChangeSubscription);

function handleStoreChangeSubscription(): void {
  FeatureFlagProvider.manageFlagChanges(store.getState().generalState.featureFlags);
}

const renderApp = (): void => {
  store.dispatch(fetchInitialConfiguration());
  ReactDOM.render(
    <Router>
      <TelemetryProvider>
        <ErrorBoundary>
          <NotificationContainer />
          <Provider store={store}>
            <App location={window.location} />
          </Provider>
        </ErrorBoundary>
      </TelemetryProvider>
    </Router>,
    document.getElementById("root") as HTMLElement
  );
};

// TODO: Create a decent no-access page: PBI https://dev.azure.com/DigitalCleaning/Digital%20Cleaning/_workitems/edit/362
// This is a temporary solution.
const renderError = (): void => {
  ReactDOM.render(<h1>No access</h1>, document.getElementById("root") as HTMLElement);
};

// TODO: Create a decent loader page for when the page is loading,
// but the user isn't logged in and hasn't been redirected to the login page yet.
// This is a temporary solution.
const renderDefault = (): void => {
  ReactDOM.render(<Loader isLoading={true}></Loader>, document.getElementById("root") as HTMLElement);
};

const accessService = new AccessService();
renderDefault();
accessService.ensureAccessAsync(renderApp, renderError);
