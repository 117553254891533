import Loader from "components/loader/loader";
import LoaderTypes from "enums/loaderTypes";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";
import EnvironmentUtils from "utils/environmentUtils";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";

import AuthorizationRoute from "../authorization/components/authorizationRoute";
import Aside from "../components/aside/aside";
import MainHeader from "../components/header/mainHeader";
import { RoutingLinks } from "../constants/routingLinks";
import Role from "../enums/role";
import CustomerActivities from "./customerData/customerActivities";
import CustomerNotificationCategories from "./customerData/customerNotificationCategories";
import CustomerBuildingOverview from "./customerManagement/components/customerBuildingOverview";
import CustomerManagement from "./customerManagement/customerManagement";
import DesignSystem from "./designSystem/DesignSystem";
import LoggingActivitiesOverview from "./home/logging/activityLogging/loggingActivitiesOverview";
import LoggingOverview from "./home/logging/routeLogging/loggingOverview";
import NotificationsOverview from "./home/notifications/notificationsOverview";
import PlanningActivitiesOverview from "./home/planning/planningActivitiesOverview";
import RoutesOverview from "./home/planning/routesOverview";
import IAppProps, { IAppDispatchProps, IAppStateProps } from "./IAppProps";
import Logbook from "./logbook/logbook";
import ActivityMasterData from "./masterdata/components/activities/activityMasterData";
import NotificationCategoryMasterData from "./masterdata/components/notificationCategories/notificationCategoryMasterData";

// Add fontawesome icons to the library
library.add(fal, fas, fad);

class App extends React.Component<IAppProps> {
  public constructor(props: IAppProps) {
    super(props);

    this.getRoutes = this.getRoutes.bind(this);
  }

  private get decideDefaultView(): string {
    if (this.props.user.role === Role.FacilityManager) {
      return RoutingLinks.logbook;
    }
    if (this.props.user == null) {
      return RoutingLinks.unauthorized;
    }
    if (!this.props.hasOperation && !this.props.hasLogbook) {
      return RoutingLinks.customerManagement;
    }
    if (!this.props.hasOperation) {
      return RoutingLinks.logbook;
    }
    if (this.props.user.role === Role.Customer) {
      return RoutingLinks.loggingActivities;
    } else {
      return RoutingLinks.planningRoutes;
    }
  }

  private decidePath(defaultPath: string): string {
    const operationPaths = [
      RoutingLinks.planningRoutes,
      RoutingLinks.planningActivities,
      RoutingLinks.loggingRoutes,
      RoutingLinks.loggingActivities,
      RoutingLinks.notifications,
    ];
    if (this.props.hasLogbook && defaultPath === RoutingLinks.logbook) {
      return RoutingLinks.logbook;
    }
    if (this.props.hasOperation && operationPaths.includes(defaultPath)) {
      return defaultPath;
    }
    return RoutingLinks.unauthorized;
  }

  private getRoutes(): JSX.Element {
    return (
      <Switch>
        <Redirect exact path={RoutingLinks.home} to={this.decideDefaultView} />
        <Redirect exact path={RoutingLinks.null} to={this.decideDefaultView} />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator, Role.Employee]}
          redirectTo={this.decideDefaultView}
          path={this.decidePath(RoutingLinks.planningRoutes)}
          component={RoutesOverview}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator, Role.Employee]}
          redirectTo={this.decideDefaultView}
          path={this.decidePath(RoutingLinks.planningActivities)}
          component={PlanningActivitiesOverview}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator, Role.Employee]}
          redirectTo={this.decideDefaultView}
          path={this.decidePath(RoutingLinks.loggingRoutes)}
          component={LoggingOverview}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator, Role.Customer, Role.Employee]}
          redirectTo={this.decideDefaultView}
          path={this.decidePath(RoutingLinks.loggingActivities)}
          component={LoggingActivitiesOverview}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator, Role.Customer, Role.Employee]}
          redirectTo={this.decideDefaultView}
          path={this.decidePath(RoutingLinks.notifications)}
          component={NotificationsOverview}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.masterData}
          component={ActivityMasterData}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.masterDataActivities}
          component={ActivityMasterData}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.masterDataNotifications}
          component={NotificationCategoryMasterData}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.customerDetailsBuildings}
          component={CustomerBuildingOverview}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.customerManagement}
          component={CustomerManagement}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.customerData}
          component={CustomerActivities}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.customerDataActivities}
          component={CustomerActivities}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator]}
          redirectTo={this.decideDefaultView}
          path={RoutingLinks.customerDataNotifications}
          component={CustomerNotificationCategories}
        />
        <AuthorizationRoute
          roles={[Role.FunctionalAdministrator, Role.Employee, Role.FacilityManager]}
          redirectTo={this.decideDefaultView}
          path={this.decidePath(RoutingLinks.logbook)}
          component={Logbook}
        />

        {EnvironmentUtils.isTestOrDevEnvironment() && <Route exact path="/design-system" component={DesignSystem} />}

        <Route component={NoMatch} />
      </Switch>
    );
  }

  private renderFullApp(content: JSX.Element): JSX.Element {
    return (
      <>
        <MainHeader />

        <div className="d-flex">
          <Route component={Aside} />
          <main>{content}</main>
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    if (this.props.isLoading) {
      return this.renderFullApp(
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Loader isLoading={true}></Loader>
        </div>
      );
    }

    return this.renderFullApp(this.getRoutes());
  }
}

const NoMatch = ({ location }): JSX.Element => (
  <div className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-2 block-content block-content--py block-content--px">
          <h2>
            No match for <code>{location.pathname}</code>
          </h2>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state: RootState): IAppStateProps => ({
  isLoading: state.generalState.loaders.some(l => l === LoaderTypes.User),
  user: state.userState.user ?? { role: Role.None },
  hasLogbook: state.customerState.hasLogbook ?? false,
  hasOperation: state.customerState.hasOperation ?? false,
  selectedCustomerId: state.customerState.selectedCustomerId,
});

const mapDispatchToProps: IAppDispatchProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
