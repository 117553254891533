import IWizardStep from "components/wizard/interfaces/IWizardStep";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { Component, ReactNode } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ImageUtils from "utils/imageUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IInputChangeEvent } from "../../components/interfaces/IInputChangeEvent";
import INotificationDetailsStepProps from "./interfaces/INotificationDetailsStepProps";
import INotificationDetailsStepState from "./interfaces/INotificationDetailsStepState";
import NotificationValidator from "./notificationValidator";

class NotificationDetailsStep extends Component<INotificationDetailsStepProps, INotificationDetailsStepState> {
  public constructor(props: INotificationDetailsStepProps) {
    super(props);

    const state = this.props.value ?? {
      description: "",
      pictures: [],
    };

    this.state = state;

    this.onChange = this.onChange.bind(this);
    this.propagateChange = this.propagateChange.bind(this);

    this.deletePicture = this.deletePicture.bind(this);
    this.handleFileSelection = this.handleFileSelection.bind(this);

    this.propagateChange();
  }

  private onChange(event: IInputChangeEvent<any>): void {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(current => ({ ...current, [name]: value }), this.propagateChange);
  }

  private propagateChange(): void {
    const isValid = NotificationValidator.areDetailsValid(this.state);
    this.props.onChange({ target: { value: this.state, name: this.props.name }, isValid });
  }

  private onFileUploadClick(e: React.MouseEvent<HTMLInputElement>): void {
    // Reset input onClick to make sure upload is possible
    e.currentTarget.files = null;
    e.currentTarget.value = "";
  }

  private deletePicture(index: number): void {
    const pictures = this.state.pictures;
    pictures.splice(index, 1);
    this.setState({
      pictures: pictures,
    });
  }

  private async handleFileSelection(e?: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const selectedImage = e?.target?.files?.[0];

    if (!e || !selectedImage) {
      return;
    }

    const imageFile = await ImageUtils.convertImageFileToBase64(selectedImage);
    if (imageFile) {
      const pictures = this.state.pictures;
      pictures.push(imageFile);

      this.setState(current => ({ ...current, pictures: pictures }), this.propagateChange);
    } else {
      // Clear HTML input, otherwise no new files can be entered once a incorrect file has been added.
      e.target.value = "";
      e.target.files = null;
    }
  }

  private renderImages(): JSX.Element {
    return (
      <>
        {this.state.pictures?.map((picture, index) => {
          return (
            <div className="input-file--item" key={index}>
              <img src={picture} alt="" />
              <FontAwesomeIcon
                icon={["fad", "circle-xmark"]}
                className="input-file--item--remove"
                onClick={(): void => this.deletePicture(index)}
              />
            </div>
          );
        })}
      </>
    );
  }

  public render(): ReactNode {
    return (
      <Form data-testid="notification-wizard-details-step">
        <Row>
          <Col className="modal__col-mb">
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.notificationwizard.take_pictures)}</Form.Label>
              <div className="input-file--container d-flex">
                {this.renderImages()}
                <label htmlFor="notification-image" className="input-file--item input-file--item--add">
                  <FontAwesomeIcon icon={["fal", "images"]} fixedWidth size="3x" />
                  <input
                    type="file"
                    className="form-control"
                    id="notification-image"
                    accept="image/*;capture=environment"
                    onClick={this.onFileUploadClick}
                    onChange={this.handleFileSelection}
                  />
                </label>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.notificationwizard.description)}</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                onChange={this.onChange}
                value={this.state.description}
                name="description"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  }
}

const wizardStep: IWizardStep = {
  form: NotificationDetailsStep,
  titleResource: "",
  name: "details",
};

export default wizardStep;
